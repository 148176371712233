import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import ButtonLink from '../components/button-link'
import Footer from '../components/footer'
import './error-page.css'

const ErrorPage = (props) => {
  return (
    <div className="error-page-container">
      <Helmet>
        <title>404</title>
        <meta name="description" content="Старонка не існуе" />
        <meta property="og:title" content="404" />
        <meta property="og:description" content="Старонка не існуе" />
      </Helmet>
      <Header></Header>
      <div className="error-page-container1 main-content-container">
        <img alt="image" src="/smile-sad.svg" className="error-page-image" />
        <span className="error-page-text">
          Нажаль, нешта пайшло ня так
        </span>
        <span className="error-page-text3">
          Даруйце, але нешта пайшло ня так.
        </span>
        <ButtonLink
          link="/application"
          button="Паспрабаваць яшчэ раз"
        ></ButtonLink>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  )
}

export default ErrorPage
