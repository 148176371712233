import React from 'react'

import PropTypes from 'prop-types'
import axios from 'axios';

import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { processFetchError } from '../utils.js'
import { AlertService } from './alert.service.js';

import './button-link.css'

const ButtonLink = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const doApiCall = (attempt) => {
    setIsLoading(true);

    axios.post(props.url, props.body || {})
      .then((response) => {
        props.history.push(props.link, response.data);
      })
      .catch((error) => {
        if (props.url === '/api/kartka/application') {
          const strings = error.split(' ');
          const errorCode = strings[strings.length - 1];
          if (errorCode === '404') {
            AlertService.showAlert('Карыстальнік не знойдзены');
            return;
          }
        }

        processFetchError(props.history.push, error);
      })
      .finally(() => setIsLoading(false));
  };

  const callApi = () => {
    if(props.url !== null && props.url !== undefined) {
      doApiCall();
    } else {
      props.history.push(props.link);
    }
  }

  return (
    <div className={`button-link-container ${props.rootClassName} `}>
      <button
        type="button"
        className="button-link-button Buttons button"
        disabled={props.disabled || isLoading}
        onClick={() => callApi()}
        title={isLoading ? 'Loading...' : ''}
      >
        {props.button}
        {isLoading && <CircularProgress size={12} className="button-link-loader" />}
      </button>
    </div>
  )
}

ButtonLink.defaultProps = {
  button: 'Button',
  rootClassName: '',
  link: '/',
  errLink: '/error-page',
  url: undefined,
  body: undefined,
  disabled: false,
}

ButtonLink.propTypes = {
  button: PropTypes.string,
  rootClassName: PropTypes.string,
  link: PropTypes.string,
  errLink: PropTypes.string,
  url: PropTypes.string,
  body: PropTypes.object,
  disabled: PropTypes.bool,
}

export default withRouter(ButtonLink)
