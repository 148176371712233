import React from 'react'

import PropTypes from 'prop-types'

import './button.css'

const Button = (props) => {
  return (
    <div className={`button-container ${props.rootClassName} `}>
      <button type="button" className="button-button Buttons button" id={props.id} onClick={() => props.callback()}>
        {props.button}
      </button>
    </div>
  )
}

Button.defaultProps = {
  rootClassName: '',
  button: 'Button',
  callback: () => {},
  id: '',
}

Button.propTypes = {
  rootClassName: PropTypes.string,
  button: PropTypes.string,
  callback: PropTypes.func,
  id: PropTypes.string,
}

export default Button
