import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import './toggle.css'

const Toggle = (props) => {
  useEffect(() => props.hook(false), []);

  return (
    <label class="switch">
        <input type="checkbox" checked={props.state} defaultChecked="false"
            onChange={ event => props.hook(event.target.checked) }/>
        <span class="slider"></span>
    </label>
  )
}


Toggle.defaultProps = {
  hook: (val) => {},
  state: false
}

Toggle.propTypes = {
  hook: PropTypes.func,
  state: PropTypes.bool
}


export default Toggle
