import React, {useEffect} from 'react'

import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import './country-dropdown.css'


const CountryDropdown = (props) => {
  const defaultCountry = countries.find(code => code.selected === true);
  useEffect(() => props.hook(defaultCountry.code), []);

  const countryOptions = countries.map(country => {
    const {code, name, ...attributes} = country;
    return ( <MenuItem value={code} {...attributes}>{name}</MenuItem> )
  });

  return (
    <div className="demographics-page-container4">
      <label className="demographics-page-text10">Краіна (тэрыторыя)</label>
      <Select sx={{ width: '100%' }} defaultValue={defaultCountry.code} onChange={event => props.hook(event.target.value)}>
        {countryOptions}
      </Select>
    </div>
  );
}

CountryDropdown.defaultProps = {
  hook: () => {},
}

CountryDropdown.propTypes = {
  hook: PropTypes.func,
}

const countries = [
  {code: "BY", name: "Беларусь", selected: true},
  {code: "AF", name: "Аўганістан"},
  {code: "AX", name: "Аландскія выспы"},
  {code: "AL", name: "Албанія"},
  {code: "DZ", name: "Алжыр"},
  {code: "AS", name: "Амерыканскае Самоа"},
  {code: "AD", name: "Андора"},
  {code: "AO", name: "Ангола"},
  {code: "AI", name: "Ангілья"},
  {code: "AQ", name: "Антарктыка"},
  {code: "AG", name: "Антыгуа і Барбуда"},
  {code: "AR", name: "Аргентына"},
  {code: "AM", name: "Арменія"},
  {code: "AW", name: "Аруба"},
  {code: "AU", name: "Аўстралія"},
  {code: "AT", name: "Аўстрыя"},
  {code: "AZ", name: "Азейбарджан"},
  {code: "BS", name: "Багамы"},
  {code: "BH", name: "Бахрэйн"},
  {code: "BD", name: "Бангладэш"},
  {code: "BB", name: "Барбадос"},
  {code: "BE", name: "Бельгія"},
  {code: "BZ", name: "Беліз"},
  {code: "BJ", name: "Бенін"},
  {code: "BM", name: "Бермуды"},
  {code: "BT", name: "Бутан"},
  {code: "BO", name: "Балівія"},
  {code: "BQ", name: "Банайрэ, Сінт-Эстаціус і Саба"},
  {code: "BA", name: "Боснія і Герцагавіна"},
  {code: "BW", name: "Баствана"},
  {code: "BV", name: "Выспа Буве"},
  {code: "BR", name: "Бразілія"},
  {code: "IO", name: "Брытанская тэрыторыя ў Індыйскім акіяне"},
  {code: "BN", name: "Бруней-Даруссалам"},
  {code: "BG", name: "Балгарыя"},
  {code: "BF", name: "Буркіна Фасо"},
  {code: "BI", name: "Бурундзі"},
  {code: "CV", name: "Кабо Верде"},
  {code: "KH", name: "Камбоджа"},
  {code: "CM", name: "Камерун"},
  {code: "CA", name: "Канада"},
  {code: "KY", name: "Кайманавы выспы"},
  {code: "CF", name: "ЦАР"},
  {code: "TD", name: "Чад"},
  {code: "CL", name: "Чылі"},
  {code: "CN", name: "Кітай"},
  {code: "CX", name: "Выспа Раства"},
  {code: "CC", name: "Какосавыя (Кілінг) выспы"},
  {code: "CO", name: "Калумбія"},
  {code: "KM", name: "Каморскія выспы"},
  {code: "CG", name: "Конго"},
  {code: "CD", name: "Конга, Дэмакратычная Рэспубліка"},
  {code: "CK", name: "Выспы Кука"},
  {code: "CR", name: "Коста Рыка"},
  {code: "CI", name: "Бераг Слановай Косці"},
  {code: "HR", name: "Харватыя"},
  {code: "CU", name: "Куба"},
  {code: "CW", name: "Кюрасао"},
  {code: "CY", name: "Кіпр"},
  {code: "CZ", name: "Чэхія"},
  {code: "DK", name: "Данія"},
  {code: "DJ", name: "Джыбуці"},
  {code: "DM", name: "Дамініка"},
  {code: "DO", name: "Дамініканская Рэспубліка"},
  {code: "EC", name: "Эквадор"},
  {code: "EG", name: "Егіпет"},
  {code: "SV", name: "Эль Сальвадор"},
  {code: "GQ", name: "Экватарыальная Гвінея"},
  {code: "ER", name: "Эрытрэя"},
  {code: "EE", name: "Эстонія"},
  {code: "SZ", name: "Эсваціні"},
  {code: "ET", name: "Эфіопія"},
  {code: "FK", name: "Фалклендскія выспы"},
  {code: "FO", name: "Фарэрскія выспы"},
  {code: "FJ", name: "Фіджы"},
  {code: "FI", name: "Фінляндыя"},
  {code: "FR", name: "Францыя"},
  {code: "GF", name: "Француская Гвінея"},
  {code: "PF", name: "Француская Палінэзія"},
  {code: "TF", name: "Французскія паўднёвыя тэрыторыі"},
  {code: "GA", name: "Габон"},
  {code: "GM", name: "Гамбія"},
  {code: "GE", name: "Грузія"},
  {code: "DE", name: "Нямеччына"},
  {code: "GH", name: "Гана"},
  {code: "GI", name: "Гібралтар"},
  {code: "GR", name: "Грэцыя"},
  {code: "GL", name: "Грынландыя"},
  {code: "GD", name: "Грэнада"},
  {code: "GP", name: "Гвадэлупа"},
  {code: "GU", name: "Гуам"},
  {code: "GT", name: "Гватэмала"},
  {code: "GG", name: "Гернсі"},
  {code: "GN", name: "Гвінея"},
  {code: "GW", name: "Гвінея-Бісаў"},
  {code: "GY", name: "Гаяна"},
  {code: "HT", name: "Гаіці"},
  {code: "HM", name: "Выспы Херд і Макдональд"},
  {code: "VA", name: "Вацікан"},
  {code: "HN", name: "Гандурас"},
  {code: "HK", name: "Ганконг"},
  {code: "HU", name: "Вугоршчына"},
  {code: "IS", name: "Ісландыя"},
  {code: "IN", name: "Індыя"},
  {code: "ID", name: "Інданэзія"},
  {code: "IR", name: "Ісламская рэспубліка Іран"},
  {code: "IQ", name: "Ірак"},
  {code: "IE", name: "Ірляндыя"},
  {code: "IM", name: "Выспа Мэн"},
  {code: "IL", name: "Ізраіль"},
  {code: "IT", name: "Італія"},
  {code: "JM", name: "Ямайка"},
  {code: "JP", name: "Японія"},
  {code: "JE", name: "Джэрсі"},
  {code: "JO", name: "Іарданія"},
  {code: "KZ", name: "Казахстан"},
  {code: "KE", name: "Кенія"},
  {code: "KI", name: "Кірібаці"},
  {code: "KP", name: "КНДР"},
  {code: "KR", name: "Рэспубліка Карэя"},
  {code: "KW", name: "Кувэйт"},
  {code: "KG", name: "Кыргызстан"},
  {code: "LA", name: "Лаос"},
  {code: "LV", name: "Латвія"},
  {code: "LB", name: "Лівант"},
  {code: "LS", name: "Лясота"},
  {code: "LR", name: "Ліберыя"},
  {code: "LY", name: "Лівія"},
  {code: "LI", name: "Ліхтэнштэйн"},
  {code: "LT", name: "Літва"},
  {code: "LU", name: "Люксембург"},
  {code: "MO", name: "Макао"},
  {code: "MG", name: "Мадагаскар"},
  {code: "MW", name: "Малаві"},
  {code: "MY", name: "Малазія"},
  {code: "MV", name: "Мальдзівы"},
  {code: "ML", name: "Малі"},
  {code: "MT", name: "Мальта"},
  {code: "MH", name: "Маршалавы выспы"},
  {code: "MQ", name: "Мартінік"},
  {code: "MR", name: "Маўрытанія"},
  {code: "MU", name: "Маўрыкій"},
  {code: "YT", name: "Маёта"},
  {code: "MX", name: "Мексіка"},
  {code: "FM", name: "Мікранэзія"},
  {code: "MD", name: "Малдова"},
  {code: "MC", name: "Манака"},
  {code: "MN", name: "Манголія"},
  {code: "ME", name: "Монтэнэгра"},
  {code: "MS", name: "Мансерат"},
  {code: "MA", name: "Марока"},
  {code: "MZ", name: "Мазамбік"},
  {code: "MM", name: "М`янма"},
  {code: "NA", name: "Намібія"},
  {code: "NR", name: "Науру"},
  {code: "NP", name: "Няпал"},
  {code: "NL", name: "Нідэрланды"},
  {code: "NC", name: "Новая Каледонія"},
  {code: "NZ", name: "Новая Зелаедыя"},
  {code: "NI", name: "Нікарагуа"},
  {code: "NE", name: "Нігер"},
  {code: "NG", name: "Нігерыя"},
  {code: "NU", name: "Ніўэ"},
  {code: "NF", name: "Выспа Норфалк"},
  {code: "MK", name: "Паўночная Македонія"},
  {code: "MP", name: "Паўночныя Марыянскія выспы"},
  {code: "NO", name: "Нарвенія"},
  {code: "OM", name: "Аман"},
  {code: "PK", name: "Пакістан"},
  {code: "PW", name: "Палаў"},
  {code: "PS", name: "Палесціна"},
  {code: "PA", name: "Панама"},
  {code: "PG", name: "Папуа новая гвінея"},
  {code: "PY", name: "Парагвай"},
  {code: "PE", name: "Перу"},
  {code: "PH", name: "Філіпіны"},
  {code: "PN", name: "Піткэрн"},
  {code: "PL", name: "Польшча"},
  {code: "PT", name: "Партугалія"},
  {code: "PR", name: "Пуэрта Рыка"},
  {code: "QA", name: "Катар"},
  {code: "RE", name: "Рэюньён"},
  {code: "RO", name: "Румунія"},
  {code: "RU", name: "Расейская Федэрацыя"},
  {code: "RW", name: "Руанда"},
  {code: "BL", name: "Сен-Бартэльмі"},
  {code: "SH", name: "Святой Алены, Ушэсця і Трыстана да Кунья"},
  {code: "KN", name: "Сэнт-Кітс і Нэвіс"},
  {code: "LC", name: "Сэнт-Люсія"},
  {code: "MF", name: "Сен-Мартэн (французская частка)"},
  {code: "PM", name: "Сен-П`ер і Мікелон"},
  {code: "VC", name: "Сэнт-Вінсэнт і Грэнадыны"},
  {code: "WS", name: "Самоа"},
  {code: "SM", name: "Сан Марына"},
  {code: "ST", name: "Сан-Тамэ і Прынсіпі"},
  {code: "SA", name: "Саўдаўская Аравія"},
  {code: "SN", name: "Сенегал"},
  {code: "RS", name: "Сербія"},
  {code: "SC", name: "Сейшэльскія выспы"},
  {code: "SL", name: "С`ера Леон"},
  {code: "SG", name: "Сінгапур"},
  {code: "SX", name: "Сінт-Мартэн (нідэрландская частка)"},
  {code: "SK", name: "Славакія"},
  {code: "SI", name: "Славенія"},
  {code: "SB", name: "Выспы Самоа"},
  {code: "SO", name: "Самалі"},
  {code: "ZA", name: "ПАР"},
  {code: "GS", name: "Паўднёвая Джорджыя і Паўднёвыя Сандвічавы выспы"},
  {code: "SS", name: "Паўднёвы Судан"},
  {code: "ES", name: "Іспанія"},
  {code: "LK", name: "Шры Ланка"},
  {code: "SD", name: "Судан"},
  {code: "SR", name: "Сурынам"},
  {code: "SJ", name: "Шпіцберген і Ян-Маен"},
  {code: "SE", name: "Швэцыя"},
  {code: "CH", name: "Швейцарыя"},
  {code: "SY", name: "Сірыя"},
  {code: "TW", name: "Тайвань"},
  {code: "TJ", name: "Таджыкістан"},
  {code: "TZ", name: "Танзанія"},
  {code: "TH", name: "Тайланд"},
  {code: "TL", name: "Тымор-Лешці"},
  {code: "TG", name: "Тога"},
  {code: "TK", name: "Такелау"},
  {code: "TO", name: "Тонга"},
  {code: "TT", name: "Трынідад і Табага"},
  {code: "TN", name: "Туніс"},
  {code: "TR", name: "Турцыя"},
  {code: "TM", name: "Туркменістан"},
  {code: "TC", name: "Выспы Тэркс і Кайкас"},
  {code: "TV", name: "Тувалу"},
  {code: "UG", name: "Уганда"},
  {code: "UA", name: "Украіна"},
  {code: "AE", name: "ААЭ"},
  {code: "GB", name: "Вялікабрытанія"},
  {code: "US", name: "ЗША"},
  {code: "UM", name: "Аддаленыя малыя выспы ЗША"},
  {code: "UY", name: "Уругвай"},
  {code: "UZ", name: "Узбекістан"},
  {code: "VU", name: "Вануату"},
  {code: "VE", name: "Венесуэла"},
  {code: "VN", name: "В`етнам"},
  {code: "VG", name: "Віргінскія выспы (Брытанскія)"},
  {code: "VI", name: "Віргінскія выспы (ЗША)"},
  {code: "WF", name: "Уоліс і Футуна"},
  {code: "EH", name: "Заходняя Сахара"},
  {code: "YE", name: "Емен"},
  {code: "ZM", name: "Замбія"},
  {code: "ZW", name: "Зімбабвэ"},
  {code: "__", name: "ІНШАЕ"}
];

export default CountryDropdown