import React from 'react'

import axios from 'axios';
import { useState, useEffect } from 'react';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { AlertService } from '../../components/alert.service.js';
import { CONSENT_PAGE_I18N } from './consent-page-i18n.js';

import './consent-page.css';


const OAuth2ConsentPage = (props) => {
  const redirectUrl = new URLSearchParams(props.location.search).get('redirectUrl');
  const [form, setForm] = useState({scopes: [], remember: false});
  const [serviceName, setServiceName] = useState(null);

  useEffect(() => {
    axios.get(redirectUrl)
      .then((response) => {
        setForm(prevState => ({...prevState, consentChallenge: response.data.consentChallenge, scopes: response.data.requestedScopes}));
        setServiceName(response.clientName);
      })
      .catch((error) => {
        AlertService.showAlert(`Памылка: ${error}`);
      });
  }, [])

  const sendAccept = (accept) => {
    axios.post('/api/oauth2/consent', { ...form, action: accept ? 'ACCEPT' : 'REJECT' })
      .then((response) => {
        window.location.href = response.data.redirectTo;
      })
      .catch((error) => {
        AlertService.showAlert(`Памылка: ${error}`);
      });
  };

  return (
    <div className="application-page-container">
      <Header></Header>
      <div className="application-page-container1 main-content-container">
        <div className="content-wrapper">
          <h3 className="content-line">Сэрвіс {serviceName || 'ХХХ'} просіць вас прадставіць наступныя даныя:</h3>
          <div>
            {
              form.scopes.map(scope =>
                <div className="scope-item" key={scope}>{CONSENT_PAGE_I18N[scope] || scope}</div>
              )
            }
          </div>
        </div>
        <div className="button-container">
          <button type="button" className="button-button Buttons button" onClick={() => sendAccept(true)}>Дазволіць</button>
          <button type="button" className="button-button Buttons button" onClick={() => sendAccept(false)}>Адхіліць</button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default OAuth2ConsentPage
