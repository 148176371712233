import { useState } from 'react';
import Alert from '@mui/material/Alert';

import { AlertService } from './alert.service';

import './alert.css'

export const AlertComponent = () => {
    const [message, setMessage] = useState(null);
    AlertService.updateAlert = setMessage;

    if (!message) {
        return <></>;
    }

    return (
        <div className="alert-container">
            <Alert severity="error">{message}</Alert>
        </div>
    )
}
