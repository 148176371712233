import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-container ${props.rootClassName} `}>
      <footer className="footer-footer">
        <span className="footer-text">© Кіберпартызаны</span>
      </footer>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: '',
  button: 'Button',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
  button: PropTypes.string,
}

export default Footer
