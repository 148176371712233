import React from 'react';

import axios from 'axios';
import { useState, useEffect } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { AlertService } from '../../components/alert.service.js';

import './login-page.css';


const OAuth2LoginPage = (props) => {
  const urlProps = new URLSearchParams(props.location.search);
  const oauthUrl = '/oauth2/auth?' + new URLSearchParams(
    {
      client_id: urlProps.get('client_id'),
      response_type: urlProps.get('response_type'),
      redirect_uri: urlProps.get('redirect_uri'),
      scope: urlProps.get('scope'),
      state: urlProps.get('state'),
    }
  ).toString()

  const [fileName, setFileName] = useState('');
  const [loginChallenge, setLoginChallenge] = useState('');
  const [serviceName, setServiceName] = useState(null);

  useEffect(() => {
    axios.get(oauthUrl)
      .then((response) => {
        setLoginChallenge(response.data.loginChallenge);
        setServiceName(response.clientName);
      })
    .catch((error) => {
      AlertService.showAlert(`Памылка: ${error}`);
    });
  }, [])

  const sendLogin = (event) => {
    event.preventDefault();
    const data = new FormData(event.target)

    if (data.get('file').size === 0) {
      AlertService.showAlert('Калі ласка, загрузіце картку');
      return;
    }

    axios.post('/api/oauth2/login', data)
      .then((response) => {
        if (response.isError) {
          props.history.push(`${encodeURIComponent(response.data.redirectUrl)}`)
        } else {
          props.history.push(`/k-auth/consent?redirectUrl=${encodeURIComponent(response.data.redirectUrl)}`);
        }
      })
      .catch((error) => {
        AlertService.showAlert(`Памылка: ${error}`);
      });
  };

  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded?.name);
  }

  const rejectLogin = () => {
    axios.post('/api/oauth2/login/reject', {'loginChallenge': loginChallenge})
      .then((response) => {
        if (response.isError) {
          props.history.push(`${encodeURIComponent(response.data.redirectUrl)}`)
        }
      })
      .catch((error) => {
        AlertService.showAlert(`Памылка: ${error}`);
      });
  }

  const navigateToApplication = () => props.history.push('/application');

  return (
    <div className="application-page-container">
      <Header></Header>
      <div className="application-page-container1 main-content-container">
        <div className="service-name">Сэрвіс {serviceName || 'XXX'}</div>
        <form encType='multipart/form-data' onSubmit={sendLogin} className="login-form">
          <div className="fields-container">
              <label className="custom-file-upload">
                <input name="file" type="file" accept=".jwt" onChange={handleFileChange}/>
                {
                  fileName ?
                    <FileDownloadDoneIcon sx={{ fontSize: 28, transform: 'translateY(5px)', marginRight: '5px', color: 'green' }}></FileDownloadDoneIcon> :
                    <FileUploadIcon sx={{ fontSize: 28, transform: 'translateY(5px)', marginRight: '5px' }}></FileUploadIcon>
                }
                <span>{ fileName || 'Загрузіць картку' }</span>
              </label>
            <input name="loginChallenge" className="hidden-input" value={loginChallenge}></input>
          </div>
          <div className="login-button-container">
            <button type="submit" className="button-button Buttons button">Увайсці</button>
            <button type="button" className="button-button Buttons button" onClick={rejectLogin}>Адхіліць</button>
            <button type="button" className="button-button Buttons button" onClick={navigateToApplication}>Атрымаць Картку</button>
          </div>
        </form>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default OAuth2LoginPage
