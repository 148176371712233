import React from 'react'

import PropTypes from 'prop-types'

import './client-side-download.css'

const ClientSideDownload = (props) => {
  const base64Content = btoa(props.content);
  return (
    <div className={`client-side-download-container ${props.rootClassName} `}>
      <a className="client-side-download-client-side-download Buttons button"
        download={props.filename} target="_blank" href={`data:text/plain;base64,${base64Content}`}>
        {props.button}
      </a>
    </div>
  )
}

ClientSideDownload.defaultProps = {
  rootClassName: '',
  button: 'Button',
  filename: 'file.txt',
  content: '',
}

ClientSideDownload.propTypes = {
  rootClassName: PropTypes.string,
  button: PropTypes.string,
  filename: PropTypes.string,
  content: PropTypes.string,
}

export default ClientSideDownload
