import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

import Header from '../components/header';
import Button from '../components/button';
import Footer from '../components/footer';
import { AlertService } from '../components/alert.service.js';

import './verification-page.css'

const VerificationPage = (props) => {
  const content = renderContent(props.history);
  return (
    <div className="verification-page-container">
      <Helmet>
        <title>Верыфікацыя</title>
        <meta name="description" content="Верыфікацыя асабістасці" />
        <meta property="og:title" content="Верыфікацыя" />
        <meta property="og:description" content="Верыфікацыя асабістасці" />
      </Helmet>
      <Header></Header>
        {content}
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  )
}

function renderContent(history) {
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selfieParams, setSelfieParams] = useState({});

  useEffect(() => {
     const startButton = document.getElementById('verification-startbutton');
     if(startButton === null || startButton === undefined) {
      return;
     }
     startButton.addEventListener(
       "click",
       (ev) => {
         takePicture(selfieParams.canvas, selfieParams.width, selfieParams.height,
            selfieParams.photo, selfieParams.video);
         ev.preventDefault();
         setFinished(true);
       },
       false,
     );
  }, [started])

  const sendPhoto = () => {
    const canvas = document.getElementById('verification-canvas');
    canvas.toBlob((blob) => {
      if (blob && !isLoading) {
        const formData = new FormData();
        formData.set('photo', blob);
        setIsLoading(true);
        axios.post('/api/kartka/application/selfie', formData)
          .then(() => {
            history.push('/issuing');
          })
          .catch((error) => {
            AlertService.showAlert('Памылка');
          })
          .finally(() => setIsLoading(false));
        }
    });
  }

  const content = (<div className="verification-page-text1">
    <span className="welcome-page-text03">
      Цяпер нам патрэбна пераканацца, што вы - гэта насамрэч вы. Будзьласка,
      прыгатуйцеся паказаць ваш твар і націсніце кнопку Пачаць.
    </span>
    <div className="verification-picture-container">
      <div className="verification-camera">
        <video id="verification-video">Відэаплынь недаступная.</video>
        {
          started &&
          <Button
            button="Сфатаграфаваць"
            id="verification-startbutton"
            rootClassName={!started ? 'hidden' : ''}
          ></Button>
        }
        {
          !started && <div className="video-overlay"></div>
        }
        {
          !started && <Button
              rootClassName="verification-page-button"
              button="Пачаць"
              callback={() => { addListenersForPlayer(setStarted, setFinished, setSelfieParams) }}
            ></Button>
        }
      </div>
      <canvas id="verification-canvas"></canvas>
      <div className="photo-output">
        <img id="verification-photo" alt="Кантэйнер для вашай фатаграфіі" />
      </div>
    </div>
    {
      finished && <Button
        button="Далей"
        rootClassName="verification-continue"
        callback={() => sendPhoto()}
      ></Button>
    }
  </div>);

  return (<div id="content" className="verification-page-container1 main-content-container">
    <h1 className="verification-page-text">Верыфікуйце асабістасць</h1>
      {isLoading && <div className="page-overlay">
          <CircularProgress size={60} />
        </div>
      }
      {content}
  </div>)
}

export default VerificationPage


function addListenersForPlayer(onStartCallback, onPhotoCallback, setSelfieParams) {
  let width = 320;
  let height = 240;
  let streaming = false;

  const video = document.getElementById('verification-video');
  const canvas = document.getElementById('verification-canvas');
  const photo = document.getElementById('verification-photo');

  navigator.mediaDevices
    .getUserMedia({ video: true, audio: false })
    .then((stream) => {
      video.srcObject = stream;
      video.play();
      onStartCallback(true);
    })
    .catch((err) => {
      console.error(`An error occurred: ${err}`);
    });

  video.addEventListener(
    "canplay",
    (ev) => {
      if (!streaming) {
        if (video.videoWidth >= video.videoHeight) {
          height = (video.videoHeight / video.videoWidth) * width;
        } else {
          height = 320;
          width = (video.videoWidth / video.videoHeight) * height;
        }

        video.setAttribute("width", width);
        video.setAttribute("height", height);
        canvas.setAttribute("width", width);
        canvas.setAttribute("height", height);
        streaming = true;
      }
    },
    false,
  );

  setSelfieParams({
    canvas: canvas,
    width: width,
    height: height,
    photo: photo,
    video: video
  })

  clearPhoto(canvas, photo);
}

function clearPhoto(canvas, photo) {
  const context = canvas.getContext("2d");
  context.fillStyle = "#eee";
  context.fillRect(0, 0, canvas.width, canvas.height);

  const data = canvas.toDataURL("image/png");
  photo.setAttribute("src", data);
}

function takePicture(canvas, width, height, photo, video) {
  const context = canvas.getContext("2d");
  if (width && height) {
    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);

    const data = canvas.toDataURL("image/png");
    photo.setAttribute("src", data);
  } else {
    clearPhoto(canvas, photo);
  }
}
