import React from 'react'

import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react';
import { Stack, TextField } from "@mui/material";

import Header from '../components/header'
import ButtonLink from '../components/button-link'
import Footer from '../components/footer'

import './application-page.css'

const ApplicationPage = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [buttonLinkAttrs, setButtonLinkAttrs] = useState({ disabled: true });

  useEffect(() => {
    const isAllFieldsPresent =
      form.identif &&
      form.lastName;
    const isErrors = Object.values(errors).some(val => !!val);
    if (!isAllFieldsPresent || isErrors) {
      setButtonLinkAttrs({ disabled: true });
    } else {
      setButtonLinkAttrs({ disabled: false });
    }
  }, [errors, form]);

  const changeForm = (e) => {
    let { value, name } = e.target;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    if (typeof value === 'string') {
      value = value.trim();
    }
    setForm((prevState) => ({ ...prevState, [name]: value }));
    validate(e);
  };

  const validate = (event) => {
    let { value, name } = event.target;
    switch(name) {
      case 'lastName': {
        if (!value) {
          setErrors((prevState) => ({ ...prevState, [name]: 'Памылка: поле абавязковае' }));
        } else {
          setErrors((prevState) => ({ ...prevState, [name]: null }));
        }
        break;
      }
      case 'identif': {
        const isError = !value || !/^[A-Z0-9]{14}$/.test(value);
        if (isError) {
          setErrors((prevState) => ({ ...prevState, [name]: 'Памылка: нумар несапраўдны' }));
        } else {
          setErrors((prevState) => ({ ...prevState, [name]: null }));
        }
        break;
      }
    }
  }

  return (
    <div className="application-page-container">
      <Helmet>
        <title>Атрымаць Картку</title>
        <meta name="description" content="Атрымаць Картку Беларуса" />
        <meta property="og:title" content="Атрымаць Картку" />
        <meta property="og:description" content="Атрымаць Картку Беларуса" />
      </Helmet>
      <Header></Header>
      <div className="application-page-container1 main-content-container">
        <h1 className="application-page-text">
          Запоўніце асабістыя звесткі
        </h1>
        <span className="application-page-text3">
           Тым, што запаўняеце палі асабістымі дадзенымі, вы дэкларуеце вашу асабістасць перад Карткай.
          На наступным кроке мы папросім вас паказаць ваш твар на камеру, каб пацвердзіць або спраставаць
          што вы насамрэч той кім прадстаўляецесь.
        </span>
        <form className="application-form" onSubmit={event => event.preventDefault()}>
          <Stack spacing={2} sx={{ marginBottom: '40px' }}>
            <TextField
              label="Ідэнтыфікацыйны нумар"
              name="identif"
              placeholder="3090976A096PB9"
              required
              variant="outlined"
              autoComplete="off"
              error={!!errors.identif}
              helperText={errors.identif}
              onChange={changeForm}
            />
            <TextField
              label="Прозвішча"
              name="lastName"
              placeholder="Васількевіч"
              required
              variant="outlined"
              autoComplete="off"
              error={!!errors.lastName}
              helperText={errors.lastName}
              onChange={changeForm}
            />
          </Stack>
          <ButtonLink
            button="Далей"
            {...buttonLinkAttrs}
            url="/api/kartka/application"
            body={form}
            rootClassName="application-page-button"
            link="/verification">
          </ButtonLink>
        </form>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default ApplicationPage
