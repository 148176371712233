import React from 'react'

import PropTypes from 'prop-types'
import { AlertComponent } from './alert.js';

import './header.css'

const Header = (props) => {
  return (
    <div className={`header-container ${props.rootClassName} `}>
      <AlertComponent />
      <a
        href="/"
        rel="noreferrer noopener"
        className="header-link"
      >
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="header-image"
        />
      </a>
      <div className="version-mark" title="Гэта бэта версія сайта. Карткі могуць быць адкліканы ў любы момант">
        БЭТА ВЕРСІЯ
      </div>
    </div>
  )
}

Header.defaultProps = {
  rootClassName: '',
  image_src: '/logo.png',
  heading: 'Картка Беларуса',
  empty: ' ',
  image_alt: 'image',
}

Header.propTypes = {
  rootClassName: PropTypes.string,
  image_src: PropTypes.string,
  heading: PropTypes.string,
  empty: PropTypes.string,
  image_alt: PropTypes.string,
}

export default Header
