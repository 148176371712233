import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import ButtonLink from '../components/button-link'
import Footer from '../components/footer'
import './welcome-page.css'

const WelcomePage = () => {
  return (
    <div className="welcome-page-container">
      <Helmet>
        <title>Картка Беларуса</title>
        <meta name="description" content="Картка Беларуса" />
        <meta property="og:title" content="Картка Беларуса" />
        <meta property="og:description" content="Картка Беларуса" />
      </Helmet>
      <Header></Header>
      <div className="welcome-page-container1 main-content-container">
        <div className="welcome-page-steps">
          <h1 className="welcome-page-text">
            Атрымаць Картку Беларуса
          </h1>
          <span className="welcome-page-text03">
              На гэтым сайце вы зможаце атрымаць Картку Беларуса. Звычайна, гэты
            працэс займае ня больш за 15 хвілін. Вам спатрэбіцца запоўніць
            пэўныя асабістыя дадзенныя і спраўдзіць вашую асабістасць за
            дапамогай відэа-верыфікацыі вашага твара. Будзьласка, пераканайцеся
            што вы маеце добрыя інтэрнет звязок і відэакамеру.
          </span>
          <h2 className="welcome-page-text04">
            Як гэта працуе
          </h2>
          <div className="welcome-page-container2">
            <div className="welcome-page-step">
              <h2 className="welcome-page-step-number">
                1
              </h2>
              <h2 className="welcome-page-step-name">
                <span>Прадстаўцеся</span>
              </h2>
              <span className="welcome-page-step-description">
                Напішыце хто вы ёсць, каб распачаць верыфікацыю.
              </span>
            </div>
            <div className="welcome-page-step">
              <h2 className="welcome-page-step-number">
                2
              </h2>
              <h2 className="welcome-page-step-name">
                <span>Верыфікуйцеся</span>
              </h2>
              <span className="welcome-page-step-description">
                Пакажыце свой твар на камеру, каб спраўдзіць хто вы ёсць.
              </span>
            </div>
            <div className="welcome-page-step">
              <h2 className="welcome-page-step-number">
                3
              </h2>
              <h2 className="welcome-page-step-name">
                <span>Зачакайце</span>
              </h2>
              <span className="welcome-page-step-description">
                Трошкі зачакайце пакуль мы праводзім пераверкі.
              </span>
            </div>
            <div className="welcome-page-step">
              <h2 className="welcome-page-step-number">
                4
              </h2>
              <h2 className="welcome-page-step-name">
                <span>Зберажыце картку</span>
              </h2>
              <span className="welcome-page-step-description">
                Спампуйце картку і зберажыце ў бяспечным месцы.
              </span>
            </div>
          </div>
        </div>
        <ButtonLink
          button="Атрымаць Картку"
          link="/application">
        </ButtonLink>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default WelcomePage
