export const CONSENT_PAGE_I18N = {
    'surrogate.is18YearsOld': 'Ці маеце 18 гадоў',
    'vc.did': 'Ідентыфікацыйны нумар карткі',
    'vc.issuer': 'Орган які выдаў дакумент',
    'vc.subject.citizenship': 'Грамадзянства',
    'vc.subject.dayOfBirth': 'Дата нараджэння',
    'vc.subject.demographics.city': 'Горад',
    'vc.subject.demographics.countryCode': 'Код краіны',
    'vc.subject.fullNameBy.firstName': 'Імя (бел)',
    'vc.subject.fullNameBy.lastName': 'Фамілія (бел)',
    'vc.subject.fullNameBy.middleName': 'Імя па бацьку (бел)',
    'vc.subject.fullNameEn.firstName': 'Імя (англ)',
    'vc.subject.fullNameEn.lastName': 'Фамілія (англ)',
    'vc.subject.fullNameEn.middleName': 'Імя па бацьку (англ)',
    'vc.subject.fullNameRu.firstName': 'Імя (ру)',
    'vc.subject.fullNameRu.lastName': 'Фамілія (ру)',
    'vc.subject.fullNameRu.middleName': 'Імя па бацьку (ру)',
    'vc.subject.kartkaId': 'Унікальны ідэнтыфікатар грамадзяніна',
    'vc.subject.legacyPassport.identif': 'Ідэнтыфікацыйны нумар (з пашпарта)',
    'vc.subject.legacyPassport.issuedAt': 'Дата выдачы пашпарта',
    'vc.subject.legacyPassport.issuedBy': 'Орган, які выдаў пашпарт',
    'vc.subject.legacyPassport.number': 'Нумар пашпарта',
    'vc.subject.legacyPassport.series': 'Серыя пашпарта',
    'vc.subject.sex': 'Пол',
    'vc.type': 'Тып карткі',
}
