import React from 'react'

import { Helmet } from 'react-helmet'
import { useState } from 'react';

import { TextField } from "@mui/material";
import Header from '../components/header'
import Toggle from '../components/toggle'
import ButtonLink from '../components/button-link'
import CountryDropdown from '../components/country-dropdown'
import Footer from '../components/footer'
import './demographics-page.css'

const DemographicsPage = (props) => {
  const [countryCode, setCountryCode] = useState("");
  const [city, setCity] = useState("");
  const [acceptProcessing, setAcceptProcessing] = useState(false);

  const updateCity = newVal => {
    setCity(newVal);
    setAcceptProcessing(true);
  }
  const updateCountryCode = newVal => {
    setCountryCode(newVal);
    setAcceptProcessing(true);
  }

  return (
    <div className="demographics-page-container">
      <Helmet>
        <title>Дэмаграфічныя звесткі</title>
        <meta name="description" content="Дэмаграфічныя звесткі" />
        <meta property="og:title" content="Дэмаграфічныя звесткі" />
        <meta property="og:description" content="Дэмаграфічныя звесткі" />
      </Helmet>
      <Header></Header>
      <div className="demographics-page-container1 main-content-container">
        <h1 className="demographics-page-text">
          <span>Дэмаграфічныя звесткі</span>
          <br></br>
        </h1>
        <span className="demographics-page-text03">
          <p>
            Будзьласка, дадайце краіну, рэгіён і горад, дзе вы зараз трывала
            пражываеце.
          </p><p>
            Гэтыя звесткі ня ёсць абавязковымі, але дапамогуць нам разам з вамі
            лепей разумець дзе мешкаюць беларусы.
          </p>
        </span>
        <div className="demographics-page-container3">
          <CountryDropdown hook={val => updateCountryCode(val)}></CountryDropdown>
          <div className="demographics-page-container5">
            <span className="demographics-page-text11">
              Дакладнае месца, да горада
            </span>
            <TextField
              name="loc"
              placeholder="Полацк"
              variant="outlined"
              sx={{ width: '100%' }}
              onChange={event => updateCity(event.target.value)}
            />
          </div>
        </div>
        <div className="demographics-page-container2">
          <Toggle hook={ val => setAcceptProcessing(val) } state={acceptProcessing}></Toggle>
          <span className="demographics-page-text07">
            Дазваляю апрацаваць звесткі аб месцы майго пастойгана пражывання.
          </span>
        </div>
      </div>
      <div className="demographics-page-container6">
        <ButtonLink
          button="Далей"
          url="/api/kartka/application/demographics" body={ {countryCode, city, acceptProcessing} }
          link="/issuing">
        </ButtonLink>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default DemographicsPage
