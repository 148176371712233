import React from 'react'
import axios from 'axios';

import { useState, useEffect } from 'react';

import Header from '../../components/header'
import Footer from '../../components/footer'

// TODO: REMOVE ME. It's just to have demo app for dev tests
const OAuth2DemoPage = (props) => {
  const [clients, setClients] = useState([])

  useEffect(() => {
    axios.get('/api/oauth2/demo')
    .then((response) => {
      console.log(response);
      setClients(response.data) // for demo only. URL for client have to be static
    })
    .catch((error) => {
      console.log("ERROR ", error);
    });
  }, [])

  const clientsHtml = clients.map(client => renderClient(props, client))
  return (
    <div className="application-page-container">
      <Header></Header>
      <div className="application-page-container1 main-content-container">
        <h3>Это 3-сторонний сайт, который использует картку для логина</h3>
        {clientsHtml}
      </div>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

function renderClient(props, client) {
  const initLogin = () => window.location = client.oauthUrl
  const redirectUris = client.redirectUris.join('; ')
  const grantTypes = client.grantTypes.join('; ')
  return (
    <div>
      <p>ID: {client.id}</p>
      <p>Name: {client.name}</p>
      <p>Secret: {client.secret}</p>
      <p>Scopes: {client.scope}</p>
      <p>Grant Types: {grantTypes}</p>
      <p>Redirect URIs: {redirectUris}</p>
      <p>OAuth URL: {client.oauthUrl}</p>
      <button type="button" className="button-button Buttons button" onClick={initLogin}>Працягнуць з Карткай</button>
      <hr style={{
        backgroundColor: "black",
        height: "1px"
      }}/>
    </div>
  )
}

export default OAuth2DemoPage
