import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './page404.css'

const Page404 = () => {
  return (
    <div className="page404-container">
      <Helmet>
        <title>404</title>
        <meta name="description" content="Старонка не існуе" />
        <meta property="og:title" content="404" />
        <meta property="og:description" content="Старонка не існуе" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header></Header>
      <div className="page404-container1 main-content-container">
        <img alt="image" src="/smile-sad.svg" className="page404-image" />
        <span className="page404-text">
          <span>Нажаль, такой старонкі не існуе.</span>
          <br></br>
        </span>
      </div>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default Page404
