import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import { useState, useEffect } from 'react';
import axios from 'axios';

import './style.css'
import CsrfContext from './context.js'
import KartkaIssuePage from './views/kartka-issue-page'
import DemographicsPage from './views/demographics-page'
import VerificationPage from './views/verification-page'
import Page404 from './views/page404'
import ApplicationPage from './views/application-page'
import ErrorPage from './views/error-page'
import WelcomePage from './views/welcome-page'
import OAuth2DemoPage from './views/oauth2/demo-page'
import OAuth2LoginPage from './views/oauth2/login-page'
import OAuth2ConsentPage from './views/oauth2/consent-page'

const App = () => {
  let csrf = '';

  useEffect(() => {
    axios.interceptors.request.use((config) => {
      if (config?.headers) {
        config.headers['x-csrf-token'] = window.sessionStorage ? window.sessionStorage.getItem('kartka-csrf') : csrf;
      }
      return config;
    }, (error) => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      const csrfToken = response.headers['x-csrf-token'];
      if (csrfToken) {
        if (window.sessionStorage) {
          window.sessionStorage.setItem('kartka-csrf', csrfToken);
        } else {
          csrf = csrfToken;
        }
      }
      return response;
    }, (error) => {
        return Promise.reject(error.message);
    });
  }, []);

  return (
    <Router>
      <Switch>
        <Route component={WelcomePage} exact path="/" />
        <Route component={ApplicationPage} exact path="/application" />
        <Route component={VerificationPage} exact path="/verification" />
        <Route component={DemographicsPage} exact path="/demographics" />
        <Route component={KartkaIssuePage} exact path="/issuing" />
        <Route component={ErrorPage} exact path="/error-page" />
        <Route component={OAuth2LoginPage} exact path="/k-auth/login" />
        <Route component={OAuth2ConsentPage} exact path="/k-auth/consent" />
        <Route component={OAuth2DemoPage} exact path="/demo" /> // TODO: REMOVE ME
        <Route component={Page404} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
