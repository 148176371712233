import React, {useState, useEffect} from 'react'

import { Helmet } from 'react-helmet'

import axios from 'axios';

import Header from '../components/header'
import ClientSideDownload from '../components/client-side-download'
import Footer from '../components/footer'
import './kartka-issue-page.css'
import { processFetchError } from '../utils.js'

const KartkaIssuePage = (props) => {
  const [kartka, setKartka] = useState('');
  const [status, setStatus] = useState('IN_PROGRESS');

  const fetchKartka = (attempt) => {
    axios.post("/api/kartka/application/issue", {})
    .then((response) => {
      setStatus('SUCCESS');
      setKartka(response.data);
    })
    .catch((error) => {
      processFetchError(props.history.push, error)
    });
  }

  useEffect(() => setTimeout(() => fetchKartka(), 250), []);

  const content = (status === 'IN_PROGRESS')
      ? renderWaiting()
      : renderKartka(kartka);

  return (
    <div className="kartka-issue-page-container">
      <Helmet>
        <title>Атрыманне Карткі</title>
        <meta name="description" content="Атрыманне Карткі Беларуса" />
        <meta property="og:title" content="Атрыманне Карткі" />
        <meta property="og:description" content="Атрыманне Карткі Беларуса" />
      </Helmet>
      <Header></Header>
        {content}
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  )
}

function renderWaiting() {
  return (
  <div className="kartka-issue-page-container1 main-content-container">
    <h1 className="kartka-issue-page-text">Зачакайце хвіліначку</h1>
    <span className="kartka-issue-page-text1">
      <span>
        Ідзе апрацоўка вашага запыта. Будзьласка, закакайце.
      </span>
      <br></br>
      <span className="welcome-page-text03">
        Як толькі мы выканаем усе пераверкі - вы адразу зможаце зберагчы Картку сабе на прыладу.
      </span>
      <br></br>
    </span>
    <div class="ring-spinner"><div></div><div></div><div></div><div></div></div>
  </div>
  );
}

function renderKartka(kartka) {
  if (!kartka) {
    return (<div></div>);
  }

  const rawName = parseName(kartka);
  const firstLetter = rawName.substring(0, 1);
  const nameExceptFirstLetter = rawName.substring(1);
  const name = firstLetter.toUpperCase() + nameExceptFirstLetter.toLowerCase();

  return (
  <div className="kartka-issue-page-container1 main-content-container">
    <h1 className="kartka-issue-page-text">Зберажыце вашу Картку</h1>
    <span className="kartka-issue-page-text1">
      <span>
        <span class="applicant-name">{name}</span>!
        Віншуем, вы паспяхова прайшлі верыфікацыю і атрымалі вашу Картку Беларуса.
      </span>
      <br></br>
      <span className="welcome-page-text03">
        Гэтая картка ёсць вашым дакумэнтам пасведчання асабістасці і месціць
        вашыя персанальныя дадзенныя, таму пераканайцеся што вы яе захаваеце
        ў бяспечнае месца.
      </span>
      <br></br>
    </span>
    <p id="jwt-text" className="kartka-issue-page-text6">
      {kartka}
    </p>
    <ClientSideDownload
      button="Спампаваць як файл" filename="kartka-by.jwt" content={kartka}></ClientSideDownload>
  </div>
  );
}

function parseName(kartkaJwt) {
  const base64Url = kartkaJwt.split(".")[1];

  const pads = base64Url.length % 4;
  const base64 = base64Url.replace(/-/g, '+')
      .replace(/_/g, '/')
      .padEnd(base64Url.length + (pads === 0 ? 0 : 4 - pads), '=')

  const binString = atob(base64);
  const rawString = Uint8Array.from(binString, (m) => m.codePointAt(0));
  const payloadString = new TextDecoder().decode(rawString);

  const payload = JSON.parse(payloadString);
  return payload.vc.subject.fullNameBy.firstName;
}

export default KartkaIssuePage
