
// export function processFetchError(selfFunc, attempt, router, error) {
//   if(error) {
//     console.error(error);
//   }

//   if(!attempt) {
//     attempt = 1;
//   }

//   if(attempt <= 3) {
//     setTimeout(() => selfFunc(attempt + 1), attempt * 755);
//   } else {
//     router('/error-page');
//   }
// }


export function processFetchError(router, error) {
  console.error(error);
  router('/error-page');
}

